import { Lightbulb } from '@mui/icons-material';
import {
    Avatar,
    Box,
    FormControl,
    InputLabel,
    Typography,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ButtonFL } from '../../components/ButtonFL';
import { apiGetCampanhas } from '../../services';
import { Loader } from '../../components/Loader';
import { apiGetComunidades } from '../../services/apiGetComunidades';
import { TextEditor } from '../../components/TextEditor';

export const Oportunidade = ({ oportunidade, handleFechar, handleSalvar }) => {
    const [comunidades, setComunidades] = useState([]);
    const [requisitosContent, setRequisitosContent] = useState(oportunidade.requisitos || '');
    const [detalhesContent, setDetalhesContent] = useState(oportunidade.detalhes || '');
    const [termosContent, setTermosContent] = useState(oportunidade.termos_condicoes || '');
    const [form, setForm] = useState({
        id: oportunidade.id || 0,
        tipo: oportunidade.tipo || '',
        comunidade: oportunidade.comunidade || 0,
        titulo: oportunidade.titulo || '',
        status: oportunidade.status || 'Em andamento',
        aceitando_inscricoes: oportunidade.aceitando_inscricoes,
        periodo_inscricao_de: oportunidade.periodo_inscricao_de
            ? new Date(oportunidade.periodo_inscricao_de).toISOString().slice(0, 16)
            : '',
        periodo_inscricao_ate: oportunidade.periodo_inscricao_ate
            ? new Date(oportunidade.periodo_inscricao_ate).toISOString().slice(0, 16)
            : '',
        recompensa_tipo: oportunidade.recompensa_tipo || '',
        recompensa_valor: oportunidade.recompensa_valor || '',
        prazo_entrega: oportunidade.prazo_entrega
            ? new Date(oportunidade.prazo_entrega).toISOString().slice(0, 16)
            : '',
        entregas: oportunidade.entregas || '',
        vagas: oportunidade.vagas || 0,
        requisitos: oportunidade.requisitos || '',
        detalhes: oportunidade.detalhes || '',
        termos_condicoes: oportunidade.termos_condicoes || '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSwitchChange = (event) => {
        const { name, checked } = event.target;
        setForm({
            ...form,
            [name]: checked,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const updatedForm = {
            ...form,
            requisitos: requisitosContent,
            detalhes: detalhesContent,
            termos_condicoes: termosContent,
        };
        handleSalvar(updatedForm);
    };

    useEffect(() => {
        const fetchData = async () => {
            const dataComunidades = await apiGetComunidades();
            setComunidades(dataComunidades);
        };

        fetchData();
    }, [oportunidade.id]);

    if (!comunidades || comunidades.length == 0) {
        return <Loader />;
    }

    return (
        <Box
            sx={{
                margin: 2,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--white-color)',
            }}
        >
            <Avatar
                sx={{
                    m: 1,
                    mt: 3,
                    bgcolor: 'var(--primary-color)',
                }}
            >
                <Lightbulb />
            </Avatar>

            <Typography component="h1" variant="h5">
                {oportunidade.id ? 'Alterar Oportunidade' : 'Adicionar Oportunidade'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ boxSizing: 'border-box', width: '100%', p: 4 }}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="tipo-label">Tipo</InputLabel>
                    <Select
                        labelId="tipo-label"
                        name="tipo"
                        value={form.tipo}
                        onChange={handleInputChange}
                        label="Tipo"
                    >
                        <MenuItem value="Desafio">Desafio</MenuItem>
                        <MenuItem value="Entrega">Entrega</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel id="comunidade-label">Comunidade</InputLabel>
                    <Select
                        labelId="comunidade-label"
                        name="comunidade"
                        value={form.comunidade}
                        onChange={handleInputChange}
                        label="Comunidade"
                    >
                        {comunidades?.map((comunidade) => (
                            <MenuItem key={comunidade.id} value={comunidade.id}>
                                {comunidade.titulo}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    margin="normal"
                    label="Título"
                    name="titulo"
                    value={form.titulo}
                    onChange={handleInputChange}
                />

                <FormControl fullWidth margin="normal">
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                        labelId="status-label"
                        name="status"
                        value={form.status}
                        onChange={handleInputChange}
                        label="Status"
                    >
                        <MenuItem value="Em andamento">Em andamento</MenuItem>
                        <MenuItem value="Encerrada">Encerrada</MenuItem>
                    </Select>
                </FormControl>

                <FormControlLabel
                    control={
                        <Switch
                            checked={form.aceitando_inscricoes}
                            onChange={handleSwitchChange}
                            name="aceitando_inscricoes"
                        />
                    }
                    label="Aceitando Inscrições"
                />

                <TextField
                    fullWidth
                    margin="normal"
                    label="Período de Inscrição (De)"
                    name="periodo_inscricao_de"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    value={form.periodo_inscricao_de}
                    onChange={handleInputChange}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    label="Período de Inscrição (Até)"
                    name="periodo_inscricao_ate"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    value={form.periodo_inscricao_ate}
                    onChange={handleInputChange}
                />

                <FormControl fullWidth margin="normal">
                    <InputLabel id="recompensa-tipo-label">Recompensa Tipo</InputLabel>
                    <Select
                        labelId="recompensa-tipo-label"
                        name="recompensa_tipo"
                        value={form.recompensa_tipo}
                        onChange={handleInputChange}
                        label="Recompensa Tipo"
                    >
                        <MenuItem value="Dinheiro">Dinheiro</MenuItem>
                        <MenuItem value="Pontos">Pontos</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    margin="normal"
                    label="Recompensa Valor"
                    name="recompensa_valor"
                    type="number"
                    value={form.recompensa_valor}
                    onChange={handleInputChange}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    label="Prazo para Entrega"
                    name="prazo_entrega"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    value={form.prazo_entrega}
                    onChange={handleInputChange}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    label="Vagas"
                    name="vagas"
                    type="number"
                    value={form.vagas}
                    onChange={handleInputChange}
                />

                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Requisitos
                    </Typography>
                    <TextEditor
                        editorContent={requisitosContent}
                        setEditorContent={setRequisitosContent}
                        height="200px"
                    />
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Detalhes
                    </Typography>
                    <TextEditor editorContent={detalhesContent} setEditorContent={setDetalhesContent} height="200px" />
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Termos e Condições
                    </Typography>
                    <TextEditor editorContent={termosContent} setEditorContent={setTermosContent} height="200px" />
                </Box>

                <Box sx={{ mt: 4 }}>
                    <ButtonFL fullWidth type="submit" variant="contained" bgcolor="var(--success-color)">
                        Salvar
                    </ButtonFL>
                    <ButtonFL fullWidth onClick={handleFechar}>
                        Cancelar
                    </ButtonFL>
                </Box>
            </Box>
        </Box>
    );
};
